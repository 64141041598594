<nav
  class="navbar navbar-expand-lg navbar-transparent navbar-absolute bg-primary">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-toggle">
        <button type="button" class="navbar-toggler" (click)="sidebarToggle()">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand" style="font-size: 30px; margin-top: 30px">
        {{ getTitle() }}
      </a>
    </div>
    <div
      class="collapse navbar-collapse justify-content-end"
      id="collapseExample"
      [ngbCollapse]="!isCollapsed"
    ></div>
  </div>
</nav>

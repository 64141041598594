import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class InactivityService {
  private idleTimeout: any;
  private readonly idleTimeInMs: number = 15 * 60 * 1000; // 15 minutos

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private toastrService: ToastrService
  ) {
    this.startWatching();
  }

  private resetTimer() {
    clearTimeout(this.idleTimeout);
    //this.idleTimeout = setTimeout(() => this.logout(), this.idleTimeInMs);
  }

  private logout() {
    this.toastrService.clear();
    this.toastrService.show(
      `<span class="now-ui-icons ui-1_bell-53"></span>Sesión cerrada por inactividad.</b>`,
      "",
      {
        disableTimeOut: true,
        closeButton: true,
        enableHtml: true,
        toastClass: "alert alert-danger alert-with-icon",
        positionClass: "toast-top-right",
      }
    );
    localStorage.clear();
    this.router.navigate(["/auth/login"]);
  }

  private startWatching() {
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener("mousemove", () => this.resetTimer());
      window.addEventListener("keydown", () => this.resetTimer());
      window.addEventListener("click", () => this.resetTimer());
      window.addEventListener("scroll", () => this.resetTimer());
      window.addEventListener("touchstart", () => this.resetTimer());
      this.resetTimer();
    });
  }
}
